<template lang="pug">
.game(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container
    v-row.justify-center.mt-12
      v-card.text-center.pa-1(
        width="300",
        color="grey lighten-3",
        elevation="0"
      )
        .text-subtitle {{ formatDate(gameData.date) }}
    v-row
      v-col.d-flex.justify-center(col="12")
        img.home_team.clickable.temp_logo_hidden(
          @click="onTeamClick(true)",
          v-if="gameData.home_team_logo",
          :src="gameData.home_team_logo.image",
          height="120",
          width="120",
          contain,
          style="flex-basis: 120px"
        )
        .scores.d-flex.justify-center.align-center
          .home_score.text-center
            h5.font-weight-bold(
              style="font-size: 40px",
              :style="[gameData.home_team_pts > gameData.away_team_pts ? { color: '#ff6050' } : {}]"
            ) {{ gameData.home_team_pts }}
            span.clickable.text-body-2(@click="onTeamClick(true)") {{ gameData.home_team_name }}

          span.vs.font-weight-bold.font-italic(style="font-family: Helvetica") VS
          .away_score.text-center
            h5.font-weight-bold(
              style="font-size: 40px",
              :style="[gameData.away_team_pts > gameData.home_team_pts ? { color: '#ff6050' } : {}]"
            ) {{ gameData.away_team_pts }}
            span.clickable.text-body-2(@click="onTeamClick(false)") {{ gameData.away_team_name }}
        img.away_team.clickable.temp_logo_hidden(
          @click="onTeamClick(false)",
          v-if="gameData.away_team_logo",
          :src="gameData.away_team_logo.image",
          height="120",
          width="120",
          contain,
          style="flex-basis: 120px"
        )
    v-row.table_row(style="margin-top: 100px")
      v-col.table.table_col(cols="12")
        table
          thead
            tr.bottom_head
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                title="隊伍",
                :offset="0"
              )
              template(v-if="gameData")
                th.bdr(v-for="index in gameData.quarter_count") {{ "第" + index + "節" }}
              template(v-if="gameData.ot_count > 0")
                th.bdr(v-for="index in gameData.ot_count") {{ "OT" + index }}
              th 總計
          tbody
            tr
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                :title="gameData.home_team_name",
                :offset="0"
              )
              td.bdr.hover(
                v-for="index in gameData.quarter_count",
                @click="onTeamStatClick(gameData.home_team__team_id, index)",
                style="cursor: pointer"
              ) {{ gameData.home_team_quarter_pts[index - 1] }}
              template(v-if="gameData.ot_count > 0")
                td.bdr.hover(
                  v-for="index in gameData.ot_count",
                  @click="onTeamStatClick(gameData.home_team__team_id, index + gameData.quarter_count)",
                  style="cursor: pointer"
                ) {{ gameData.home_team_quarter_pts[gameData.quarter_count + index - 1] }}
              td.bdr {{ gameData.home_team_pts }}
            tr
              sticky-column.bdl.bdr2(
                style="width: 150px; min-width: 150px",
                :title="gameData.away_team_name",
                :offset="0"
              )
              td.bdr.hover(
                v-for="index in gameData.quarter_count",
                @click="onTeamStatClick(gameData.away_team__team_id, index)",
                style="cursor: pointer"
              ) {{ gameData.away_team_quarter_pts[index - 1] }}
              template(v-if="gameData.ot_count > 0")
                td.bdr.hover(
                  v-for="index in gameData.ot_count",
                  @click="onTeamStatClick(gameData.away_team__team_id, index + gameData.quarter_count)",
                  style="cursor: pointer"
                ) {{ gameData.away_team_quarter_pts[gameData.quarter_count + index - 1] }}
              td.bdr {{ gameData.away_team_pts }}
  v-container.tab_container(style="padding: 0", fluid)
    v-tabs(
      show-arrows,
      background-color="transparent",
      ripple="false",
      v-model="tab",
      centered
    )
      v-tabs-slider(color="primary")

      v-tab(
        @click="toPage(index)",
        :ripple="false",
        v-for="(item, index) in tabList",
        :key="index"
      ) {{ item }}
  v-main
    transition(name="page", mode="out-in")
      router-view
  custom-footer
  event-video-dialog(
    v-model="isDialogShow"
    :gameId="gameId"
    :quarter="quarter"
    :teamId="teamId"
    :playerId="playerId"
    :stat="stat"
  )
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getGameData, getEventByStat } from "@/api/game";
import moment from "moment";
import "moment/locale/zh-tw";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";
import { getIdFromUrl } from "vue-youtube";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeagueGame",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    StickyColumn,
    CustomFooter,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    EventVideoDialog,
  },
  data() {
    return {
      league: this.$route.params.leagueId,
      season: this.$route.params.seasonId,
      seasonSegmentId:
        Number(this.$route.params.seasonSegmentId) > 0
          ? Number(this.$route.params.seasonSegmentId)
          : null,
      gameId: Number(this.$route.params.id),
      gameData: {},
      teamGameDataPoints: [],
      tab: 0,
      tabList: ["Box Score"],

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      eventList: [],
      teamId: null,
      playerId: null,
      quarter: null,
      stat: "",

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
      //TODO: siteTitle
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },

      quarter_count: 0,
      ot_count: 0,
    };
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    "$route.name"() {
      this.checkTab();
    },
  },
  async created() {
    if (this.$route.name != null) {
      this.checkTab();
    }
    await this.getGameData();
  },
  methods: {
    onTeamClick(isHomeTeam) {
      let id;

      if (isHomeTeam) {
        id = this.gameData.home_team__team_id;
      } else {
        id = this.gameData.away_team__team_id;
      }

      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.league,
          teamId: id,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
    onTeamStatClick(teamId, quarter) {
      this.teamId = teamId;
      this.stat = "pts";
      this.quarter = quarter;
      this.isDialogShow = true;
    },
    async getGameData() {
      let temp = {
        game_id: this.gameId,
      };
      await getGameData(temp).then((response) => {
        this.gameData = response.data;
        this.quarter_count = this.gameData.quarter_count;
        this.metaData.title =
          this.gameData.home_team_name +
          "vs." +
          this.gameData.away_team_name +
          " | " +
          this.formatDate(this.gameData.date) +
          " | " +
          this.metaData.title;
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD dddd");
    },
    toPage(index) {
      switch (index) {
        case 0:
          this.$router.push({
            name: "LeagueGameBoxScore",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              id: game.game_id,
            },
          });
          break;
        case 1:
          this.$router.push({ name: "LeagueGameAdvancedStatistics" });
          break;
      }
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.eventList && this.eventList.length > 0) {
        this.playVideo(this.eventList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.eventList && this.eventList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.eventList[0].video,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.eventList && this.eventList.length > 0) {
          if (this.playAllIndex + 1 < this.eventList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.eventList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(event, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(event);
    },
    onFeedbackBtnClick(event) {
      event.league = this.league;
      event.team = this.dialogTeam;
      this.feedBackPlay = event;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(event) {
      this.scrollToTop();
      var startSeconds =
        event.fta > 0
          ? moment.duration(event.video_end_at).asSeconds() - 14
          : moment.duration(event.video_start_at).asSeconds();

      if (event.youtube_urls.length > 0) {
        let youtubeId = getIdFromUrl(event.youtube_urls[0].url);

        this.YTPlayer.loadVideoById({
          videoId: youtubeId,
          startSeconds: startSeconds - event.video_duration,
          endSeconds: moment.duration(event.video_end_at).asSeconds() + 2,
        });
      }
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    checkTab() {
      if (this.$route.name == "LeagueGameBoxScore") {
        this.$nextTick(() => {
          this.tab = 0;
        });
      } else if (this.$route.name == "LeagueGameAdvancedStatistics") {
        this.$nextTick(() => {
          this.tab = 1;
        });
      }
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/game";
</style>
